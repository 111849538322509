



























































import { Vue, Component, Prop } from 'vue-property-decorator';

import ItemCollection from '@/core/support/collection/ItemCollection';
import ORMCollection from '@/core/bridge/orm/ORMCollection';
import Search from '@/core/support/search/Search';
import TableTemplateInterface from '@/shared/lib/interfaces/TableTemplateInterface';

import DataTableSearchField from './tools/DataTableSearchField.vue';
import Pagination from '../../../DataPagination.vue';
import DataTableCurrentLimit from '@/shared/resources/components/tables/data-table/table/tools/DataTableCurrentLimit.vue';
import DataTableLimitSelect from '@/shared/resources/components/tables/data-table/table/tools/DataTableLimitSelect.vue';
import Header from '@/shared/resources/components/Header.vue';

@Component({
  components: {
    Header,
    DataTableLimitSelect,
    DataTableCurrentLimit,
    Pagination,
    DataTableSearchField,
  },
})
export default class TableToolsTop extends Vue {
  /**
   * Props
   */
  @Prop() private collection!: ItemCollection | ORMCollection;
  @Prop() private template!: TableTemplateInterface;
  @Prop() private search!: Search;
  @Prop() private limit!: number;
  @Prop() private searchOptions?: any[];
  @Prop() private title?: string;
  @Prop({ default: false, type: Boolean }) private noPagination!: string;

  /**
   * Getters
   */
  private get totalGreaterThanMinLimit(): boolean {
    return this.collection.pagination.getTotal() > this.limit;
  }

  /**
   * Display getters
   */
  private get displayTitle(): boolean {
    return this.displayHeader || this.displayCurrentLimit;
  }

  private get displayHeader(): boolean {
    return !!this.title;
  }

  private get displayCurrentLimit(): boolean {
    return !this.collection.empty() && !!this.template.topLimit && !this.title;
  }

  private get displaySearchField(): boolean {
    return !!this.template.search;
  }

  private get displayPagination(): boolean {
    return !this.collection.empty() &&
      !this.noPagination &&
      !!this.template.topPagination &&
      this.totalGreaterThanMinLimit;
  }

  private get displayExtra(): boolean {
    return !!this.$slots.extra;
  }

  /**
   * Handlers
   */
  private onUpdatePage(value: number) {
    this.$emit('update:page', value);
  }

  private onUpdateSearch(search: Search) {
    this.$emit('update:search', {
      searchString: search.queryString,
      searchField: search.queryField,
    });
  }
}
